import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const experiences = [
    {
        name: 'Ingénieur logiciel',
        date: '2023 - Aujourd\'hui',
        description : 'HelloWork',
        type: 'CDI',
        link: 'https://www.hellowork.com/',
        longDescription: ''
    },
    {
        name: 'Lead Développeur',
        date: '2021 - 2022',
        description : 'WebexpR',
        type: 'Alternance',
        link: 'https://webexpr.fr/',
        longDescription: ''
    },
    {
        name: 'Développeur Web',
        date: '2019 - 2021',
        description : 'WebexpR',
        type: 'Alternance',
        link: 'https://webexpr.fr/',
        longDescription: '',
    },
    {
        name: 'Développeur Web',
        date: 'Janvier - Mars 2019',
        description : 'WebexpR',
        type: 'Stage',
        link: 'https://webexpr.fr/',
        longDescription: '',
    },
    {
        name: 'Développeur',
        date: 'Eté 2018',
        description : 'Oxylead',
        type: 'Stage',
        link: 'https://oxylead.net/',
        longDescription: '',
    },
  ]  

const ExperiencesPage = () => (
  <Layout>
    <Seo title="Expériences - Guillaume Dominici" />
    <h1 className={styles.textTitle}>
        Expériences
    </h1>
    <p className={styles.description}>
        Depuis près de 4 ans je travaille activement dans le domaine du développement web. 
        <br></br>
        Ces 3 années d'alternance m'ont permis d'acquérir de l'autonomie et un sens proactif du travail.
    </p>
    <div>
        <div>
            {experiences.map((experience, i) => (
                <div 
                    style={{
                        padding: '1rem 1rem 1px 1rem',
                        borderLeft: '2.5px solid',
                        borderColor: `rgba(${r()}, ${r()}, ${r()}, 0.5)`,
                        // borderRadius: '10px',
                        margin: '0 0 1rem 0'
                    }}    
                    >
                    <div className={styles.experiencesPart}>
                        <h3 style={{
                            float: 'left'
                        }}>{experience.name}</h3>
                        <span style={{
                            float: 'right',
                            color: 'grey',
                            fontSize: '14px',
                            marginTop: '7px'
                        }}>{experience.date}</span>
                    </div>
                    <p style={{
                        display: 'block',
                        fontSize: '14px',
                        color: 'grey',
                        margin: 0,
                    }}>
                    <a target="_blank" href={experience.link}>{experience.description}</a> - {experience.type}
                    </p>
                    <p style={{
                        fontSize: '16px',
                        marginTop: '0.5rem'
                    }}>
                        {experience.longDescription}
                    </p>
                </div>
            ))}
        </div>
    </div>
  </Layout>
)

export default ExperiencesPage

var r = () => Math.random() * 256 >> 0;
